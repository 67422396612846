var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "650px"
    },
    on: {
      "click:outside": function clickOutside($event) {
        return _vm.$emit('update:is-bio-dialog-open', false);
      }
    },
    model: {
      value: _vm.isBioDialogOpen,
      callback: function callback($$v) {
        _vm.isBioDialogOpen = $$v;
      },
      expression: "isBioDialogOpen"
    }
  }, [_c('v-card', {
    staticClass: "user-edit-info pa-sm-10 pa-3"
  }, [_c('v-card-title', {
    staticClass: "justify-center text-h5"
  }, [_vm._v(" Edit User Information ")]), _c('v-card-text', {
    staticClass: "text-center mt-n2"
  }, [_vm._v(" Updating user details will receive a privacy audit. ")]), _c('v-card-text', {
    staticClass: "mt-5"
  }, [_c('v-form', {
    staticClass: "multi-col-validation"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Full Name"
    },
    model: {
      value: _vm.userDataLocal.fullName,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "fullName", $$v);
      },
      expression: "userDataLocal.fullName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "prefix": "@",
      "outlined": "",
      "dense": "",
      "label": "Username"
    },
    model: {
      value: _vm.userDataLocal.username,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "username", $$v);
      },
      expression: "userDataLocal.username"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Billing Email"
    },
    model: {
      value: _vm.userDataLocal.email,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "email", $$v);
      },
      expression: "userDataLocal.email"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.statusOptions,
      "item-text": "title",
      "item-value": "value",
      "outlined": "",
      "dense": "",
      "label": "Status"
    },
    model: {
      value: _vm.userDataLocal.status,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "status", $$v);
      },
      expression: "userDataLocal.status"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "label": "Tax ID"
    },
    model: {
      value: _vm.userDataLocal.taxId,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "taxId", $$v);
      },
      expression: "userDataLocal.taxId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "prefix": "+1",
      "outlined": "",
      "dense": "",
      "label": "Contact"
    },
    model: {
      value: _vm.userDataLocal.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "contact", $$v);
      },
      expression: "userDataLocal.contact"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.languageOptions,
      "outlined": "",
      "dense": "",
      "label": "Language"
    },
    model: {
      value: _vm.userDataLocal.language,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "language", $$v);
      },
      expression: "userDataLocal.language"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.countries,
      "outlined": "",
      "dense": "",
      "label": "Country"
    },
    model: {
      value: _vm.userDataLocal.country,
      callback: function callback($$v) {
        _vm.$set(_vm.userDataLocal, "country", $$v);
      },
      expression: "userDataLocal.country"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_c('span', {
          staticClass: "font-weight-medium text--primary"
        }, [_vm._v("Use as a billing address?")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.isBillingAddress,
      callback: function callback($$v) {
        _vm.isBillingAddress = $$v;
      },
      expression: "isBillingAddress"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-center mt-3",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "me-3",
    attrs: {
      "color": "primary",
      "type": "submit"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_vm._v(" submit ")]), _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "secondary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.$emit('update:is-bio-dialog-open', false);
      }
    }
  }, [_vm._v(" Discard ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }