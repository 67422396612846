var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "user-bio-panel"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    staticClass: "pt-10"
  }, [_c('v-card-title', {
    staticClass: "justify-center flex-column"
  }, [_c('v-avatar', {
    staticClass: "mb-0",
    class: _vm.user.avatar ? '' : 'v-avatar-light-bg primary--text',
    attrs: {
      "color": _vm.user.avatar ? '' : 'primary',
      "size": "120",
      "rounded": ""
    }
  }, [_vm.user.profile.photo ? _c('v-img', {
    staticStyle: {
      "border": "solid 1px #eee !important"
    },
    attrs: {
      "src": _vm.user.profile.photo.thumbnail
    }
  }) : _c('span', {
    staticClass: "font-weight-semibold text-5xl"
  }, [_vm._v(_vm._s(_vm.avatarText(_vm.fullName)))])], 1), _c('span', {
    staticClass: "text-xs text--secondary mb-0",
    on: {
      "click": function click($event) {
        return _vm.$emit('openEditPhotoAside');
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-0 mb-2",
    attrs: {
      "size": "18"
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiPencilOutline) + " ")]), _vm._v(" Edit Photo ")], 1), _c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.fullName))]), _vm.user.verified_at ? _c('v-chip', {
    attrs: {
      "color": "success"
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.icons.mdiCheck))]), _vm._v(" Verified ")], 1) : _c('v-chip', {
    attrs: {
      "color": "error"
    }
  }, [_vm._v("Not Verified")])], 1), _c('v-card-text', [_c('h2', {
    staticClass: "text-xl font-weight-semibold mb-2"
  }, [_vm._v("Profile")]), _c('v-divider'), _c('v-list', [_c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Name:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.fullName))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Email:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.user.email))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Gender:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.user.profile.gender))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Phone:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v("+" + _vm._s(_vm.user.country_code) + " " + _vm._s(_vm.user.phone))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Locale:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.user.locale))])])], 1)], 1), _c('v-card-text', [_c('h2', {
    staticClass: "text-xl font-weight-semibold mb-2"
  }, [_vm._v("Distributor")]), _c('v-divider'), _c('v-list', [_c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium me-2"
  }, [_vm._v("Name:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.user.team.distributor.nicename))])]), _c('v-list-item', {
    staticClass: "px-0 mb-n2",
    attrs: {
      "dense": ""
    }
  }, [_c('span', {
    staticClass: "font-weight-medium text-no-wrap me-2"
  }, [_vm._v("Team:")]), _c('span', {
    staticClass: "text--secondary"
  }, [_vm._v(_vm._s(_vm.user.team.nicename))])])], 1)], 1), _c('v-card-actions', {
    staticClass: "justify-center"
  })], 1), _c('user-bio-edit', {
    attrs: {
      "is-bio-dialog-open": _vm.isBioDialogOpen,
      "user-data": _vm.user
    },
    on: {
      "update:isBioDialogOpen": function updateIsBioDialogOpen($event) {
        _vm.isBioDialogOpen = $event;
      },
      "update:is-bio-dialog-open": function updateIsBioDialogOpen($event) {
        _vm.isBioDialogOpen = $event;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }