<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-avatar :color="user.avatar ? '' : 'primary'" :class="user.avatar ? '' : 'v-avatar-light-bg primary--text'" size="120" rounded class="mb-0">
            <v-img v-if="user.profile.photo" :src="user.profile.photo.thumbnail" style="border: solid 1px #eee !important;"></v-img>
            <span v-else class="font-weight-semibold text-5xl">{{ avatarText(fullName) }}</span>
          </v-avatar>

          <span class="text-xs text--secondary mb-0" @click="$emit('openEditPhotoAside')">
            <v-icon size="18" class="mr-0 mb-2">
              {{ icons.mdiPencilOutline }}
            </v-icon> Edit Photo
          </span>
          <span class="mb-2">{{ fullName }}</span>

          <v-chip color="success" v-if="user.verified_at">
            <v-icon size="20" class="mr-1">{{ icons.mdiCheck }}</v-icon> Verified
          </v-chip>

          <v-chip color="error" v-else>Not Verified</v-chip>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Profile</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Name:</span>
              <span class="text--secondary">{{ fullName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Email:</span>
              <span class="text--secondary">{{ user.email }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Gender:</span>
              <span class="text--secondary">{{ user.profile.gender }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Phone:</span>
              <span class="text--secondary">+{{ user.country_code }} {{ user.phone }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Locale:</span>
              <span class="text--secondary">{{ user.locale }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <!-- <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">HealthCoin</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Remaining:</span>
              <span class="text--secondary">{{ healthCoins }}</span>
            </v-list-item>
          </v-list>
        </v-card-text> -->

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Distributor</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Name:</span>
              <span class="text--secondary">{{ user.team.distributor.nicename }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium text-no-wrap me-2">Team:</span>
              <span class="text--secondary">{{ user.team.nicename }}</span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions class="justify-center">
          <!-- <v-btn
            color="primary"
            class="me-3"
            @click="isBioDialogOpen = !isBioDialogOpen"
          >
            Edit
          </v-btn> -->
          <!-- <v-btn
            color="error"
            outlined
          >
            Suspended
          </v-btn> -->
        </v-card-actions>
      </v-card>

      <!-- edit profile dialog data -->
      <user-bio-edit :is-bio-dialog-open.sync="isBioDialogOpen" :user-data="user"></user-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiPencilOutline } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import UserBioEdit from './UserBioEdit.vue'

export default {
  components: {
    UserBioEdit,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const isBioDialogOpen = ref(false)

    const fullName = computed(() => {
      return `${props.user.profile.last_name} ${props.user.profile.first_name}`
    })

    const healthCoins = computed(() => {
      return props.user.reward_points.reduce((acc, next) => {
        return acc + next.points
      }, 0)
    })

    return {
      avatarText,
      kFormatter,

      isBioDialogOpen,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiCheck,
        mdiPencilOutline,
      },

      fullName,
      healthCoins,
    }
  },
}
</script>
